.consultantSection {
  padding: 4rem 0;
  background: linear-gradient(to bottom, rgba(38, 155, 215, 0.05), rgba(222, 4, 124, 0.05));
}

.consultant-grid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2.5rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 24px;
}

.grid-header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 2rem;
  padding: 1.8rem;
  background: #f8f9fa;
  border-radius: 8px;
  border-bottom: 2px solid rgba(38, 155, 215, 0.2);
}

.header-item {
  font-weight: 700;
  font-size: 1.3rem;
  color: #333;
  letter-spacing: 0.02em;
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 2rem;
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
  transition: all 0.3s ease;
}

.grid-row:hover {
  transform: translateX(10px);
  background: #f8f9fa;
}

.row-item {
  color: #555;
  font-size: 1.1rem;
  line-height: 1.6;
}

.internSection {
  padding: 4rem 0;
  background: linear-gradient(to bottom, rgba(38, 155, 215, 0.05), rgba(222, 4, 124, 0.05));
}

.intern-grid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2.5rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 24px;
}

.intern-grid .grid-header {
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
}

.intern-grid .grid-row {
  grid-template-columns: 1.5fr 1.5fr 1fr 1fr;
}

@media (max-width: 768px) {
  .consultantSection, .internSection {
    padding: 2rem 0;
  }

  .consultant-grid, .intern-grid {
    padding: 1rem;
    margin: 0;
  }

  /* Stack the grid items vertically on mobile */
  .grid-header, .grid-row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    min-width: unset;
  }

  /* Hide the header on mobile */
  .grid-header {
    display: none;
  }

  /* Style each row as a card */
  .grid-row {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  /* Style the items inside each row */
  .row-item {
    position: relative;
    padding: 0.5rem;
    padding-left: 120px; /* Space for labels */
    min-height: 40px;
    font-size: 0.9rem;
  }

  /* Add labels before each item */
  .row-item::before {
    content: attr(data-label);
    position: absolute;
    left: 0.5rem;
    width: 110px;
    font-weight: 600;
    color: #333;
  }

  /* Specific styling for consultant grid */
  .consultant-grid .row-item:nth-child(1)::before {
    content: "Name:";
  }
  .consultant-grid .row-item:nth-child(2)::before {
    content: "Expertise:";
  }
  .consultant-grid .row-item:nth-child(3)::before {
    content: "Duration:";
  }

  /* Specific styling for intern grid */
  .intern-grid .row-item:nth-child(1)::before {
    content: "Name:";
  }
  .intern-grid .row-item:nth-child(2)::before {
    content: "Role:";
  }
  .intern-grid .row-item:nth-child(3)::before {
    content: "Duration:";
  }
  .intern-grid .row-item:nth-child(4)::before {
    content: "Year:";
  }
}