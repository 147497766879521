.sweet-loading {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: auto
}

.sweet-loading img {
    width: 40%;
    margin-top: 2rem
}

@media only screen and (max-width: 600px) {
    .sweet-loading img {
        width: 80%

    }
  }