.aboutMainSec {
    margin-bottom: 5rem
}

.supporters-sec {
    display: flex;
    width: 100%;
    gap: 2rem;
}

.supporters-sec img {
    display: block;
    width: 30rem;
    height: 100%;
    margin: auto;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 18px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
    transition: transform 0.3s ease;
    object-fit: cover;
}

.supporters-sec img:hover {
    transform: translateY(-20px);
}

@media only screen and (max-width: 600px) {
    .supporters-sec {
        display: block;
        justify-content: center;
        align-items: center
    }
    .supporters-sec img {
        width: 22rem;
        margin-bottom: 20px;
    }
}