/* Universal styling */

/* Font Use in this website */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300400&family=Roboto:wght@300400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Roboto', sans-serif */
}

.title {
  text-align: center;
  margin: 2rem 0px;
  padding: 2rem;
  font-size: 2rem;
  font-weight: 800;
  background: -webkit-linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section {
  scroll-margin-top: calc(5rem ); 
  padding-top: 2rem;
}



/* Utilities */
:root {
  --main-color-first: #E81A22;
  --main-color-second: #314088;
  --white: #ffffff;
  --black: #000000;
  --text-color: #747474;
  --backGroundImage: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg fill-opacity='0.48'%3E%3Ccircle fill='%23FFFFFF' cx='50' cy='0' r='50'/%3E%3Cg fill='%23fdfdfd' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23fafafc' cx='50' cy='100' r='50'/%3E%3Cg fill='%23f8f8fa' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23f5f5f8' cx='50' cy='200' r='50'/%3E%3Cg fill='%23f3f3f6' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%23f0f0f5' cx='50' cy='300' r='50'/%3E%3Cg fill='%23eeeef3' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%23ebebf1' cx='50' cy='400' r='50'/%3E%3Cg fill='%23e9e9ef' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e6e6ee' cx='50' cy='500' r='50'/%3E%3Cg fill='%23e4e4ec' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23e1e1ea' cx='50' cy='600' r='50'/%3E%3Cg fill='%23dfdfe8' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23dcdce7' cx='50' cy='700' r='50'/%3E%3Cg fill='%23dadae5' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d7d7e3' cx='50' cy='800' r='50'/%3E%3Cg fill='%23d5d5e1' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%23d2d2e0' cx='50' cy='900' r='50'/%3E%3Cg fill='%23d0d0de' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23CDCDDC' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E";
}



