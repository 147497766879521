.contactus-sec {
  height: 95vh;
  position: relative;
}

.title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  padding-top: 2rem;
}

.subContactUsSec {
  background: rgb(232, 26, 34);
  background: linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
  );
  color: var(--white);
  width: 30%;
  padding: 1.5rem 0;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 18px, rgba(0, 0, 0, 0.22) 0px 5px 5px;
  letter-spacing: 0.1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  margin: 1rem 0;
}

.icons {
  margin-right: 1.2rem;
}

.contact-logo h3 {
  margin: 0;
  font-size: 1.2rem;
  word-wrap: break-word;
}

@media screen and (max-width: 768px) {
  .subContactUsSec {
    width: 90%;
    padding: 1.5rem 1rem;
  }

  .contact-logo {
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
  }

  .icons {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .contact-logo h3 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .subContactUsSec {
    width: 95%;
    padding: 1rem;
  }

  .title {
    font-size: 1.3rem;
  }
}