.reviews-section {
  padding: 4rem 0;
  background: linear-gradient(to bottom, rgba(38, 155, 215, 0.05), rgba(222, 4, 124, 0.05));
  position: relative;
}

.reviews-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem; /* Increased gap between cards */
  padding: 2rem;
  max-width: 1200px; /* Decreased from 1400px */
  margin: 0 auto;
}

.review-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  width: 500px;
  position: relative;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(123deg, rgba(38, 155, 215, 0.3), rgba(222, 4, 124, 0.3)) border-box;
  border: 2px solid transparent;
  box-shadow: 
    rgba(0, 0, 0, 0.1) 0px 10px 18px,
    rgba(38, 155, 215, 0.1) 0px 0px 0px 1px;
  transition: all 0.3s ease;
}

.review-card:hover {
  transform: translateY(-10px);
  box-shadow: 
    rgba(0, 0, 0, 0.2) 0px 20px 30px,
    rgba(38, 155, 215, 0.2) 0px 0px 0px 2px;
  border: 2px solid transparent;
  background: linear-gradient(white, white) padding-box,
              linear-gradient(123deg, rgba(38, 155, 215, 0.5), rgba(222, 4, 124, 0.5)) border-box;
}

.review-content {
  text-align: center;
}

.rating {
  margin-bottom: 1rem;
  color: #FFD700;
}

.star-icon {
  margin: 0 2px;
}

.review-text {
  font-style: italic;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  font-size: 1.1rem;
}

.reviewer-info {
  position: relative;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.reviewer-info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 2px;
  background: linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
  );
}

.reviewer-info h3 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.reviewer-info p {
  color: #666;
  font-size: 1rem;
}

.submit-review-section {
  text-align: center;
  margin: 3rem 0;
}

.submit-review-btn {
  background: linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
  );
  color: white;
  padding: 18px 35px;  /* Increased padding */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;  /* Increased font size */
  font-weight: 500;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.share-icon {
  font-size: 1.2rem;
}

.submit-review-btn:hover {
  transform: translateY(-2px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px;
}

@media (max-width: 768px) {
  .review-card {
    width: 100%;
    max-width: 400px;
  }
}