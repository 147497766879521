/* TeamCard.css */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.team-card {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  margin-bottom: 1.5rem;
  border-radius: 12px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 18px;
  transition: transform 0.3s ease;
}

.team-card:hover {
  transform: translateY(-5px);
}

.team-avatar {
  width: 218px;
  height: 230px;
  flex-shrink: 0;
  position: relative;
  border-radius: 50%;
  background: 
    linear-gradient(white, white) padding-box,
    linear-gradient(
      123deg,
      rgba(38, 155, 215, 1) 15%,
      rgba(222, 4, 124, 1) 100%
    ) border-box;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.team-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: fit;
  padding: 5px; 
}

.team-info {
  flex: 1;
  text-align: left;
}

.team-info h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 0.5rem;
  background: linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.team-info p:first-of-type {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1rem;
  font-weight: 500;
}

.team-info p:last-of-type {
  color: #666;
  line-height: 1.6;
  text-align: justify;
}

@media (max-width: 768px) {
  .team-card {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
  }

  .team-avatar {
    width: 200px;
    height: 200px;
  }

  .team-info {
    text-align: center;
  }

  .team-info p:last-of-type {
    text-align: center;
  }
}
