.aboutus {
  padding: 2rem 0;
  background: linear-gradient(to bottom, rgba(38, 155, 215, 0.05), rgba(222, 4, 124, 0.05));
}

.aboutus-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.about-img {
  flex: 1;
  display: flex;
  justify-content: center;
}

.about-img img {
  width: 100%;
  max-width: 500px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px;
  transition: transform 0.3s ease;
}

.about-img:hover img {
  transform: translateY(-10px);
}

.aboutMainHeader {
  flex: 1;
}

.aboutMainPara {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 2rem;
  text-align: justify;
  padding: 0 1rem;
  word-wrap: break-word;
  hyphens: auto;
}

.aboutHeader {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.features-container {
  display: grid;
  gap: 1rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateX(10px);
}

.correctSign {
  color: rgba(38, 155, 215, 1);
  font-size: 1.2rem;
}

.feature-item span {
  font-size: 1.1rem;
  color: #555;
}

@media (max-width: 768px) {
  .aboutus-sec {
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
  }

  .about-img img {
    max-width: 100%;
  }

  .company-name {
    font-size: 1.5rem;
    text-align: center;
  }

  .aboutHeader {
    font-size: 1.5rem;
    text-align: center;
  }

  .aboutMainPara {
    font-size: 0.95rem;
    padding: 0 0.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  .feature-item span {
    font-size: 1rem;
  }
}