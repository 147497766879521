/* Navbar.css */
.navbar {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-logo img {
  margin-left: 30px;
  width: 100%;
  max-width: 32rem;
  height: auto;
  transition: all 0.3s ease;
}

.navbar-links {
  display: flex;
  justify-content: space-evenly; 
  align-items: center;
  width: 100%;
  background: linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
);
  padding: 10px 0;
}

.navbar-links a {
  color: #fff;
  font-size: 1.0em;
  text-decoration: none;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: all 0.2s ease-in-out;
  position: relative;
  border-radius: 4px;
}

.nav-icon {
  font-size: 1.35em;
}

.navbar-links a:hover {
  color: #e8e8e8;
  background-color: rgba(226, 221, 221, 0.156); 
}

.navbar-right {
  display: flex;
  align-items: center;
}

.donate-button {
  background: white;
  color: rgb(222, 4, 124);
  padding: 10px 20px;
  border: 2px solid rgb(222, 4, 124);
  text-decoration: none;
  font-weight: 550;
  margin-right: 20px;
  transition: all 0.2s ease-in-out;
  position: relative;
  box-shadow: 
  3px 3px 0 0 rgb(222, 4, 124), 
  3px 3px 0 2px rgb(222, 4, 124), 
  rgba(0, 0, 0, 0.25) 0px 10px 18px;
  transform: translate(-3px, -3px);
}

.donate-button:hover {
  background: rgb(222, 4, 124);
  color: #fff;
  transform: translate(0, 0);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px;
}

.donate-button:active {
  transform: translate(0, 0);
  box-shadow: none;
  outline: none;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-toggle span {
  height: 3px;
  width: 25px;
  background-color: #000;
  margin: 3px 0;
  display: block;
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-logo img {
    margin-left: 0;
    max-width: 20rem; 
  }

  .navbar-logo {
    margin-bottom: 10px;
  }

  .navbar-links {
    z-index: 2;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    background: linear-gradient(
      123deg,
      rgba(38, 155, 215, 1) 15%,
      rgba(222, 4, 124, 1) 100%
  );
  }

  .navbar-links a {
    width: 100%;
    justify-content: center;
    padding: 15px;
    margin: 0;
  }

  .navbar-links a:hover {
    color: #e8e8e8;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-right {
    margin-top: 10px;
  }

  .navbar-toggle {
    display: flex;
  }
}