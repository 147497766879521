.product-sec {
  padding: 4rem 0;
  background: linear-gradient(to bottom, rgba(38, 155, 215, 0.05), rgba(222, 4, 124, 0.05));
}

.curiokaards-container {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 18px;
}

.product-media {
  flex: 1.4;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.image-carousel {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.image-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 1;
}

.prev { left: 10px; }
.next { right: 10px; }

.video-container {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.product-video {
  width: 100%;
  height: auto;
  display: block;
}

.product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.product-info h2 {
  font-size: 2.2rem;
  color: #333;
  margin-bottom: 1rem;
}

.product-info p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #666;
}

.price {
  margin: 1.5rem 0;
  font-size: 1.2rem;
  color: #333;
}

.price-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.check-icon {
  color: rgb(28, 211, 49);
}

.order-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center; 
  gap: 15px;
  padding: 15px 35px;
  width: 100%; 
  margin: 0 auto; 
  background: linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
  );
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.order-text {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
}

.order-btn:hover {
  transform: translateY(-3px);
}

.cart-icon {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.order-btn:hover .cart-icon {
  transform: translateX(3px);
}

@media (max-width: 768px) {
  .curiokaards-container {
    flex-direction: column;
  }

  .order-btn {
    width: 100%;
    justify-content: center;
    padding: 12px 24px;
    font-size: 1rem;
  }
  
  .product-media,
  .product-info {
    width: 100%;
  }

  .video-container {
    height: 230px; 
  }

  .product-video {
    width: 100%;
    height: 100%;
  }

  .image-carousel {
    height: 300px;
  }

  .product-info h2 {
    font-size: 1.8rem;
  }
}