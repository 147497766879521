footer {
  background: linear-gradient(
    123deg,
    rgba(38, 155, 215, 1) 15%,
    rgba(222, 4, 124, 1) 100%
  );
  padding: 2rem;
  color: var(--white);
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address-sec h3 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}

address {
  font-size: 0.9rem;
  line-height: 1.4;
}

.social-media-sec {
  font-size: 0.9rem;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.icons {
  transition: transform 0.2s ease;
}

.icons:hover {
  cursor: pointer;
  transform: scale(1.1);
}

a {
  color: var(--white);
  text-decoration: none;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  footer {
    display: block;
    padding: 1.5rem;
    text-align: center;
  }

  .address-sec {
    margin-bottom: 1.5rem;
  }

  .address-sec h3 {
    font-size: 1.1rem;
  }

  .social-icons {
    justify-content: center;
    gap: 2rem;
  }

  .icons {
    font-size: 1.2rem !important;
  }
}